<template>
  <div id="contents">
  <div class="dg-pop-layer pop-wd-mid st-meeting-attend on" id="popPasswordCahngeBox">
    <div class="dg-pop-hd-st2-box st-line">
      <div class="pop-h1">비밀번호 변경</div>
      <div class="btn-close-box"><a href="javascript:;" class="btn-close dg-pop-close" @click="closePopup">닫기</a></div>
    </div>
    <div class="pop-cont-box ">
      <div class="pop-mem-change-form">
        <div class="row-box">
          <span class="tit-box">현재 비밀번호</span>
          <span class="input-box">
              <input name="Pswd" type="password" v-model="pswd" class="input-st1 h40" @keyup="formatValidation" placeholder="현재 비밀번호를 입력해주세요.">
          </span>
        </div>
        <div class="row-box">
          <span class="tit-box">새 비밀번호</span>
          <span class="input-box">
            <input name="NewPswd" type="password" v-model="newPswd" class="input-st1 h40" @keyup="formatValidation" placeholder="8~15자의 영문,숫자,특수문자 조합으로 입력해 주세요.">
          </span>
        </div>
        <div class="row-box">
          <span class="tit-box">새 비밀번호 확인</span>
          <span class="input-box">
              <input name="ReNewPswd" type="password" v-model="reNewPswd" class="input-st1 h40" @keyup="formatValidation" placeholder="비밀번호를 다시 한번 입력해 주세요.">
          </span>
        </div>
        <p>
          <span v-if="validation.pswd != null" class="txt-error">{{validation.pswd}}</span>
          <span v-else-if="validation.newPswd != null" class="txt-error">{{validation.newPswd}}</span>
          <span v-else-if="validation.reNewPswd != null" class="txt-error">{{validation.reNewPswd}}</span>
        </p>
      </div>
      <div class="board-btn-box wd150 al-center mb-none">
        <a href="javascript:;" @click="changePswd" :style="styleObject">확인</a>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import UserApi from '@/api/UserApi'

export default {
  name: "ChangePswdPopup",
  data(){
    return {
      pswd: '',
      newPswd: '',
      reNewPswd: '',
      validation:{
        pswd:'',
        newPswd:'',
        reNewPswd:''
      },
      styleObject:{
        'background-color':'#666666',
        cursor:'default'
      }
    }
  },
  methods:{
    closePopup(){
      this.$emit('click:bgDimClose',false)
    },
    changePswd(){
      if(this.validation.pswd == null && this.validation.newPswd == null && this.validation.reNewPswd == null) {
        let param = new FormData();
        param.append('currentPassword', this.pswd)
        param.append('newPassword', this.newPswd)

        UserApi.updatePswd(this.$store.state.user.userid, param).then((data)=>{
          if(data) {
            if(!alert('비밀번호가 재설정되었습니다.')){
              this.closePopup()
            }
          }else{
            if(!alert('비밀번호 재설정 중 오류가 발생했습니다.')){
              this.closePopup()
            }
          }
        })
      }
    },
    formatValidation(e){
      let passwordRegExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;

      if(e.target.name == 'Pswd') {
        if (e.target.value === '') {
          this.validation.pswd = '비밀번호를 입력해주세요.'
        } else {
          let param = new FormData();
          param.append('password', e.target.value)

          UserApi.matchPswd(this.$store.state.user.userid, param).then((data) => {
            if (!data) {
              this.validation.pswd = '비밀번호가 일치하지 않습니다.'
            } else {
              this.validation.pswd = null
            }
          })
        }
      }else if(e.target.name == 'NewPswd') {
        if (e.target.value === '') {
          this.validation.newPswd = '새 비밀번호를 입력해주세요.'
        } else if (e.target.value === this.pswd) {
          this.validation.newPswd = '기존에 사용중인 비밀번호입니다.'
        } else if (!passwordRegExp.test(this.newPswd)) {
          this.validation.newPswd = '비밀번호는 8~15자 영문, 숫자, 특수문자 조합으로 입력해주세요.'
        } else {
          this.validation.newPswd = null

          if (this.reNewPswd != '') {
            if(e.target.value !== this.reNewPswd) {
              this.validation.reNewPswd = '비밀번호가 일치하지 않습니다. 입력한 비밀번호를 확인해주세요.'
            }else {
              this.validation.reNewPswd = null
            }
          }
        }
      }else if(e.target.name == 'ReNewPswd') {
        if (e.target.value === '') {
          this.validation.reNewPswd = '새 비밀번호 확인을 입력해주세요.'
        } else if (e.target.value != this.newPswd) {
          this.validation.reNewPswd = '비밀번호가 일치하지 않습니다. 입력한 비밀번호를 확인해주세요.'
        } else {
          this.validation.reNewPswd = null
        }
      }
      this.onChangeActive()
    },
    onChangeActive() {
      if (this.validation.pswd == null && this.validation.newPswd == null && this.validation.reNewPswd == null) {
        this.styleObject = {
          'background-color': '',
          cursor: 'pointer'
        }
      }else{
        this.styleObject = {
          'background-color':'#666666',
          cursor:'default'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>