<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-mypage" >

    <section class="content-wrap st-center">
      <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
        <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
        <div class="sub-tab-mb-title">
          <a href="javascript:;" class="on" @click="onClickTabMobile()" >회원정보 <i class="arrow"></i></a> 
        </div>
        <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
          <a href="javascript:;">회원정보 </a>
          <a href="javascript:;"><router-link to="/my/meetList">모임 내역</router-link></a>
          <a href="javascript:;"><router-link to="/my/videoConference">화상 회의</router-link></a>
          <a href="javascript:;"><router-link to="/my/board">내 게시글</router-link></a>
          <a href="javascript:;"><router-link to="/my/message">쪽지함</router-link></a>
          <a href="javascript:;"><router-link to="/my/inquiry">1:1 문의</router-link></a>
          <a href="javascript:;" @click="logout">로그아웃</a>
        </div>
      </div>

      <ChangePswd v-if="popupPswd" @click:bgDimClose="onClickPopupPswd"></ChangePswd>
      <ChangeMobl v-else-if="popupMobl" @click:bgDimClose="onClickPopupMobl" :userDetail="this.userDetail"></ChangeMobl>
      <VueDaumPostcode class="address-pop-layer"/>
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box mb-pd-none">

          <div class="mypage-wrap">
            <div class="aside-box">
              <div class="people-info-box">
                  <div class="img-box">
                    <img class="member-list-box" v-if="userDetail.photoFile==null" src="../../assets/front/images/icon/member.jpg"/>
                    <img class="member-list-box" v-else :src="`/attachment/user/photo/${userDetail.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
                    <div class="btn-profile-edit"><a href="javascript:;" @click="onClickImageUpload">이미지수정버튼</a></div>
                  </div>
                <input ref="imageInput" type="file" accept="image/*" hidden @change="onChangeImages">
                <div class="name-box">
                  <div class="name"><em>{{ userDetail.name }}</em><span>님</span></div>
                  <div class="cate">{{ userDetailDepartment }} / {{ memberClub(userDetail.clubGroup) }}</div>
                </div>
                <div class="btn-box">
                  <router-link to="/my/message">쪽지함</router-link>
                  <router-link to="/my/inquiry">1:1 문의</router-link>
                </div>
              </div>
              <div class="menu-box">
                <ul>
                  <li class="on"><a href="javascript:;" @click="onClickMenu">회원정보</a></li>
                  <li><router-link to="/my/inquiry">활동 내역</router-link>
                    <ul>
                      <li><router-link to="/my/inquiry">1:1 문의</router-link></li>
                      <li><router-link to="/my/board">내 게시글</router-link></li>
                      <li><router-link to="/my/message">쪽지함</router-link></li>
                    </ul>
                  </li>
                  <li><router-link to="/my/meetList">모임 내역</router-link></li>
                  <li><router-link to="/my/videoConference">화상 회의</router-link>
                    <ul>
                      <li><router-link to="/my/videoConference">전체 회의</router-link></li>
                      <li><router-link to="/my/myConference">내가 개설한 회의</router-link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="main-body-box" v-if="!revise">
              <div class="my-tit-box">회원정보</div>
              <div class="tb-01 st-w st-big mb30">
                <table>
                  <colgroup><col width="16%"><col width="34%"><col width="16%"><col width="34%"></colgroup>
                  <tbody>
                  <tr>
                    <th>이름</th>
                    <td>{{ userDetail.name }}</td>
                    <th>아이디</th>
                    <td>{{ userDetail.userId }}</td>
                  </tr>
                  <tr>
                    <th>생년월일</th>
                    <td>{{ userDetail.birthDate }}</td>
                    <th>휴대폰 번호</th>
                    <td>{{ phoneFormatter(userDetail.mobileNo) }}</td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td colspan="3">{{ userDetail.email }}</td>
                  </tr>
                  <tr>
                    <th>자택주소</th>
                    <td colspan="3">{{ userDetail.address }} {{ userDetail.addressDetail }}</td>
                  </tr>
                  <tr>
                    <th>현재 직장</th>
                    <td>{{ userDetail.company }}</td>
                    <th>현재 직책</th>
                    <td>{{ userDetail.position }}</td>
                  </tr>
                  <tr>
                    <th>최종부서<br>(전자 재임시)</th>
                    <td>{{ userDetail.retirementDepartment }}</td>
                    <th>직위<br>(전자 재임시)</th>
                    <td>{{ userDetail.retirementPosition }}</td>
                  </tr>
                  <tr>
                    <th>분과</th>
                    <td colspan="3">{{ userDetailDepartment }}</td>
                  </tr>
                  <tr>
                    <th>동호회</th>
                    <td colspan="3">{{ userDetailClubs }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="board-btn-box al-center st-two"><a href="javascript:;" @click="revise=true">정보수정</a></div>
            </div>



            <div class="main-body-box" v-else>
              <div class="my-tit-box" >회원정보</div>
              <div class="tb-01 st-w st-big mb30">
                <table>
                  <colgroup><col width="16%"><col width="34%"><col width="16%"><col width="34%"></colgroup>
                  <tbody>
                  <tr>
                    <th>이름</th>
                    <td>{{ userDetail.name }}</td>
                    <th>아이디</th>
                    <td>{{ userDetail.userId }}</td>
                  </tr>
                  <tr>
                    <th>비밀번호</th>
                    <td colspan="3"><a href="javascript:;" class="btn-mod btnPasswordChange" @click="onClickPopupPswd(true)">비밀번호 변경</a></td><!-- btnPasswordChange script 하단에 있음-->
                  </tr>
                  <tr>
                    <th>생년월일</th>
                    <td>{{ userDetail.birthDate }}</td>
                    <th>휴대폰 번호</th>
                    <td>
                      <div class="td-between">
                        {{ phoneFormatter(userDetail.mobileNo) }} <a href="javascript:;" class="btn-mod btnMobileChange" @click="onClickPopupMobl(true)">번호 변경</a><!-- btnMobileChange script 하단에 있음-->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td colspan="3">{{userDetail.email}}</td>
                  </tr>
                  <tr style="height: 120px">
                    <th>자택주소</th>
                    <td colspan="3" class="mem-info-address">
                        <a href="javascript:;" @click="openZipCode" class="btn-mod" style="margin: 5px 0">우편번호</a>
                        <input type="text" @click="openZipCode" class="input-st1 st-addr1 dg-ml-3" style="width:127px; height: 40px;" placeholder="" v-model="userDetail.zipCode"><br>
                        <input type="text" @click="openZipCode" class="input-st1 st-addr2" style="height: 40px; margin-bottom: 5px" placeholder="" v-model="userDetail.editAddress">
                        <input type="text" class="input-st1 st-addr3 dg-ml-3" style="height: 40px; margin-bottom: 5px" placeholder="상세 주소를 입력해주세요." v-model="userDetail.editAddressDetail">
                    </td>
                  </tr>
                  <tr>
                    <th>현재 직장</th>
                    <td v-if="!isEditableCompany">{{ userDetail.company }}</td>
                    <td v-else class="td-st2">
                      <input type="text" class="input-st1 wd100per mb-input-btm" v-model="userDetail.company">
                    </td>
                    <th>현재 직책</th>
                    <td v-if="!isEditablePosition">{{ userDetail.position }}</td>
                    <td v-else>
                      <input type="text" id="position" class="input-st1 wd100per mb-input-btm" v-model="userDetail.position">
                    </td>

                  </tr>
                  <tr>
                    <th>최종부서<br>(전자 재임시)</th>
                    <td>{{ userDetail.retirementDepartment }}</td>
                    <th>직위<br>(전자 재임시)</th>
                    <td>{{ userDetail.retirementPosition }}</td>
                  </tr>
                  <tr>
                    <th>분과</th>
                    <td colspan="3">{{ userDetailDepartment }}</td>
                  </tr>
                  <tr>
                    <th>동호회</th>
                    <td colspan="3">{{ userDetailClubs }}</td>
                  </tr>
                  </tbody>
                </table>
                <p class="mem-info-exp">* 비밀번호, 휴대폰 번호, 주소 외의 수정사항은 사무실 측으로 연락주세요. (TEL.02-558-3401)</p>
              </div>
              <div class="board-btn-box al-center st-two"><a href="javascript:;" class="st2" @click="onClickReviseCancel">취소</a> <a href="javascript:;" @click="onClickReviseSave">변경내용 저장</a></div>
            </div>
          </div>
        </div>
      </div>
      <!-- class : main-wrap (E) -->

    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import ChangePswd from '@/views/mypage/popup/changePswd_popup'
import ChangeMobl from '@/views/mypage/popup/changeMobl_popup'
import userApi from "@/api/UserApi";
import VueDaumPostcode from "@/views/component/vue-daum-postcode";
import UserApi from '@/api/UserApi'

export default {
  name: "myInfo",
  components:{
    ChangePswd,
    ChangeMobl,
    VueDaumPostcode
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return{
      revise:false,
      popupPswd:false,
      popupMobl:false,

      userId: this.$store.state.user.userid,
      userDetail: {},
      userDetailDepartment: '',
      userDetailClubs: '',

      isEditableCompany: false,
      isEditablePosition: false,
      tablistOpen:false,
    }
  },
  watch:{
    isOpen(e){
      if(!e){
        this.popupPswd=false
        this.popupMobl=false
      }
    }
  },
  created(){
    if(this.userId == '') {
      alert('로그인 후 이용하실 수 있습니다.')
      this.$router.push(`/login`)
    }else{
      this.getUserDetail()
    }
  },
  methods:{
    logout() {
      this.$store.dispatch('user/logout');
      window.location.href='/'
    },
    getUserDetail(){
      userApi.get(this.userId).then((data) =>{
        this.userDetail = data;
        this.userDetail.editAddress = data.address;
        this.userDetail.editAddressDetail = data.addressDetail;

        if(data.company == ''){
          this.isEditableCompany=true
        }
        if(data.position == ''){
          this.isEditablePosition=true
        }
       // this.userDetail.userHasGroups =[]
        this.userDetailClubs=''
        if(data.clubGroup != null) {
          if (data.clubGroup.length > 0) {
            for (let i = 0; i < data.clubGroup.length; i++) {
              //this.userDetail.userHasGroups.push(data.clubGroup[i].groupId);

              this.userDetailClubs += data.clubGroup[i].groupName
              if (i < data.clubGroup.length - 1) {
                this.userDetailClubs += ", "
              }
            }
          }
        }
        //this.userDetail.userHasGroups.push(data.departmentGroup[0].groupId);
        this.userDetailDepartment = data.departmentGroup[0].groupName
      })
    },
    memberClub(val){
      if(val != null) {
        let clubLength = val.length
        let userClubs = clubLength > 0 ? val[0].groupName : ''
        if (clubLength > 1) {
          userClubs += " 외 " + (clubLength - 1)
        }
        return userClubs
      }else{
        return '';
      }
    },
    onClickPopupPswd(e){
      this.popupPswd = e
      this.popupMobl = !e
      this.$emit('click:bgDim', this.popupPswd)
    },
    onClickPopupMobl(e){
      this.popupPswd = !e
      this.popupMobl = e
      this.$emit('click:bgDim', this.popupMobl)
    },
    onClickReviseCancel(){
      this.revise=false;
      this.userDetail.editAddress = this.userDetail.address;
      this.userDetail.editAddressDetail = this.userDetail.addressDetail;
    },
    onClickReviseSave(val){
      this.userDetail.userHasGroupObjects = [...this.userDetail.userHasGroups]
      delete this.userDetail.userHasGroups;
      delete this.userDetail.roleHasMenu;

      let onlyNumber = []
      this.userDetail.userHasRoles.forEach(e=>{
        onlyNumber.push(e.id.roleId)
      })
      this.userDetail.userHasRoles = onlyNumber

      if(this.userDetail.password) {
        delete this.userDetail.password;
      }

      this.userDetail.address = this.userDetail.editAddress;
      this.userDetail.addressDetail = this.userDetail.editAddressDetail;

      UserApi.save(this.userDetail).then(() =>{
        if(val!='photo'){
          alert('회원정보가 수정되었습니다.')
        }
        this.getUserDetail()
      }).catch(()=>{
        alert('회원 등록 중 오류가 발생하였습니다. \n오류가 계속되면 운영자에게 문의해주세요.')
      })

      this.revise = false
    },
    phoneFormatter(val) {
      if (val != null) {
        if (val.length == 11) {
          return val.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        } else if (val.length == 8) {
          return val.replace(/(\d{4})(\d{4})/, "$1-$2");
        } else {
          if (val.indexOf("02") == 0) {
            return val.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          } else {
            return val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          }
        }
      }
    },
    openZipCode() {
      let _user = this.userDetail;
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function(data) {
          _user.zipCode = data.zonecode;
          _user.editAddress = data.address;
        }}).open();
    },
    photoRemove() {
      this.userDetail.photoFile = null;
    },
    onClickImageUpload() {
      this.$refs.imageInput.click();
    },
    onChangeImages(e) {
      userApi.uploadImage(e.target.files[0])
          .then((response) => {
            // console.log('업로드성공'+JSON.stringify(response));
            this.userDetail.photoFile = response.saveName;
            this.onClickReviseSave('photo');
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    onClickMenu(){
      this.onClickReviseCancel();
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    }
  },
}
</script>

<style scoped>

</style>