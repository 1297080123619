<template>
  <div class="dg-pop-layer pop-wd-mid st-meeting-attend on" id="popMobileCahngeBox">
    <div class="dg-pop-hd-st2-box st-line">
      <div class="pop-h1">휴대폰번호 변경</div>
      <div class="btn-close-box"><a href="javascript:;" class="btn-close dg-pop-close" @click="closePopup">닫기</a></div>
    </div>
    <div class="pop-cont-box ">
      <form action="">
        <div class="pop-mem-change-form">
          <div class="row-box">
            <span class="tit-box">휴대폰번호</span>
            <span class="input-box st-mobile-ok">
                  <input name="" v-model="moblNo" class="input-st1 h40" placeholder="휴대폰 번호를 입력해주세요." @input="onInputMobile" maxlength="11">
                  <a href="javascript:;" @click="onClickSendAuth">인증번호 발송</a>
            </span>
          </div>
          <div class="row-box">
            <span class="tit-box">인증번호</span>
            <span class="input-box">
                <input name="" v-model="authNo" class="input-st1 h40" placeholder="휴대폰으로 전송된 인증번호를 입력해주세요." @input="onInputEvent" maxlength="6">
            </span>
          </div>
          <p>
            <span class="txt-error" v-if="validation.authNo!=null">{{ validation.authNo }}</span>
          </p>
        </div>
      </form>
      <div class="board-btn-box wd150 al-center mb-none"><a href="javascript:;" @click="changeMobl" :style="styleObject">변경</a></div>
    </div>
  </div>
</template>

<script>
import UserApi from "@/api/UserApi";

export default {
  name: "ChangeMoblPopup",
  props:{
    userDetail:{}
  },
  watch:{
    isAuthenticated() {
      if (this.isAuthenticated && this.isSent) {
        this.styleObject = {
          'background-color': '',
          cursor: 'pointer'
        }
      } else {
        this.styleObject = {
          'background-color': '#666666',
          cursor: 'default'
        }
      }
    },
    isSent(){
      if (this.isAuthenticated && this.isSent) {
        this.styleObject = {
          'background-color': '',
          cursor: 'pointer'
        }
      } else {
        this.styleObject = {
          'background-color': '#666666',
          cursor: 'default'
        }
      }
    }
  },
  data(){
    return {
      user:this.userDetail,

      authNo:'',
      moblNo:'',

      isSent:false,
      validation: {
        authNo: null
      },
      isAuthenticated:false,
      styleObject:{
        'background-color':'#666666',
        cursor:'default'
      }
    }
  },
  methods:{
    closePopup(){
      this.$emit('click:bgDimClose',false);
    },
    changeMobl(){
      if(this.moblNo=='' || !this.isSent || !this.isAuthenticated){
        return;
      }

      if(this.isAuthenticated) {
        this.user.userHasGroupObjects = [...this.userDetail.userHasGroups]
        delete this.user.userHasGroups;
        delete this.user.roleHasMenu;

        let onlyNumber = []
        this.userDetail.userHasRoles.forEach(e=>{
          onlyNumber.push(e.id.roleId)
        })
        this.user.userHasRoles = onlyNumber

        if(this.userDetail.password) {
          delete this.user.password;
        }

        this.user.mobileNo=this.moblNo.trim();

        UserApi.save(this.user).then(() => {
          alert('휴대폰 번호가 변경되었습니다.')
        }).catch(error => {
          alert(error.response?.data?.message ?? 'error');
          // console.log(error);
        })

        this.closePopup();
      }
    },
    onClickSendAuth(){
      if(this.moblNo.length<8 || this.moblNo.length>12){
        return this.validation.authNo = '휴대폰 번호를 확인해주세요.';
      }

      UserApi.existsMobile(this.moblNo).then((data)=> {
        if (!data) {
          UserApi.sendMobileAuthNo(this.moblNo).then(() => {
            this.isSent = true;
            this.validation.authNo = '인증번호를 입력해주세요.';
            return alert("인증번호를 발송했습니다.\n인증번호가 오지 않으면 정보를 \n다시 확인해주세요.")
          }).catch(() => {
            return alert("오류가 발생하였습니다. 다시 시도해주세요.");
          })
        } else {
          this.validation.authNo = '이미 사용중인 핸드폰번호 입니다. 관련 문의는 사무실 측으로 연락주세요. (TEL.02-558-3401)';
        }
      })
    },
    onInputEvent(e){
      this.authNo = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      this.isAuthenticated=false;

      if(e.target.value==''){
        this.validation.authNo = '인증번호를 입력해주세요.';
      }else {
        UserApi.checkMobileAuthNo(this.moblNo, e.target.value).then(data => {
          if (data) {
            this.validation.authNo = null;
            this.isAuthenticated = true;
          } else {
            this.validation.authNo = '인증번호를 다시 확인해주세요.';
          }
        })
      }
    },
    onInputMobile(e){
      this.isSent=false;
      this.moblNo = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').trim();
    },

  }
}
</script>

<style scoped>

</style>